import React from 'react';
import PropTypes from 'prop-types';

import getUrl from '@utils/getUrl';
import useGetLoan from '@utils/useGetLoan';
import urls from '@constants/urls';

import TransactionsList from '@components/containers/TransactionsList';

const LoanTransaction = ({ loanId }) => {
  const { loading, loan } = useGetLoan(loanId, getUrl(urls.HOME));
  if (!loan) return null;

  const url = getUrl(urls.HOME.MY_LOANS.DETAILS, { loanId });
  const { transactions } = loan;

  return <TransactionsList url={url} loading={loading} transactions={transactions} />;
};

LoanTransaction.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanTransaction;
